
































































import {
  Component, Vue, Watch,
} from 'vue-property-decorator';
import AllianzDepositRecurringConfirmationViewModel
  from '@/vue-app/view-models/allianz-dashboard/deposits/recurring_deposits/allianz-deposit-recurring-confirmation-view-model';

@Component({ name: 'RecurringDepositConfirmation' })
export default class RecurringDepositConfirmation extends Vue {
  @Watch('view_model.set_state.item.contributions_saved')
  onContributionsChange() {
    this.view_model.formatSummary();
  }

  @Watch('view_model.set_state.item.dates_changed')
  onDatesChange() {
    this.view_model.formatSummary();
  }

  @Watch('view_model.set_state.item.wants_back_to_one_contribution')
  wantsBackToOneContributionChange() {
    this.view_model.formatSummary();
  }

  view_model = Vue.observable(
    new AllianzDepositRecurringConfirmationViewModel(this),
  );

  nextStep() {
    this.view_model.confirmOperation();
  }
}

