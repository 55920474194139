import TYPES from '@/types';
import Vue from 'vue';
import { parseCurrencyToNumber } from '@/vue-app/utils/parse-currency-to-number';
import { currencyFormat } from '@/vue-app/utils/currency';

// Application
import {
  UpdateAllianzRecurringContributionsCommand,
} from '@/modules/my-investment/allianz/recurring-contributions/application/commands';

// Domain
import {
  SetRecurringContributionsStateManager,
} from '@/modules/my-investment/allianz/recurring-contributions/domain/state/set-recurring-contributions-state-manager';
import {
  UpdateAllianzRecurringContributionsEntity,
} from '@/modules/my-investment/allianz/recurring-contributions/domain/entities/update-allianz-recurring-contributions-entity';
import {
  UpdateRecurringContributionDistributionEntity,
} from '@/modules/my-investment/allianz/recurring-contributions/domain/entities/update-recurring-contribution-distribution-entity';
import {
  StateManager,
} from '@/modules/my-investment/allianz/recurring-contributions/domain/state/state-manager';
import {
  StateManager as AllianzAccountStateManager,
} from '@/modules/my-investment/allianz/allianz-account/domain/state/state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

type SummaryItem = {
  label: string;
  value: string;
  label_class_name?: string;
  value_class_name?: string;
}

export default class AllianzDepositRecurringConfirmationViewModel {
  @Inject(TYPES.UPDATE_ALLIANZ_RECURRING_CONTRIBUTIONS_COMMAND)
  private readonly update_contributions_command!: UpdateAllianzRecurringContributionsCommand;

  @Inject(TYPES.SET_RECURRING_CONTRIBUTIONS_STATE_MANAGER)
  private readonly set_state_manager!: SetRecurringContributionsStateManager;

  @Inject(TYPES.ALLIANZ_RECURRING_CONTRIBUTIONS_STATE_MANAGER)
  private readonly state_manager!: StateManager;

  @Inject(TYPES.ALLIANZ_ACCOUNT_STATE_MANAGER)
  private readonly account_state_manager!: AllianzAccountStateManager;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  private readonly view: Vue;

  i18n_namespace = 'components.allianz-dashboard.deposits.recurring_contribution_confirm';

  items: Array<SummaryItem> = []

  set_state = this.set_state_manager.state;

  state = this.state_manager.state;

  account_state = this.account_state_manager.state;

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  formatCollectionDay = (collection_day: number) => (collection_day > 0 ? `${this.translate('day')}
    ${collection_day} ${this.translate('of_month')}`
    : this.translate('recurring_not_defined'));

  formatNewCollectionDay = (collection_day: number, new_collection_day: number) => (`${this.translate('day')}
    ${new_collection_day} ${this.translate('of_month')}`);

  formatNewAmount = (amount: number, new_amount: number) => (
    `${currencyFormat(new_amount)} MXN`);

  appendItemToSummary = (
    label: string,
    value: string,
    label_class_name = 'primary--text',
    value_class_name = 'sky-blue--text',
  ) => {
    this.items.push({
      label, value, label_class_name, value_class_name,
    });
  }

  formatSummary = () => {
    this.items = [];
    this.appendItemToSummary(this.translate('policy'), this.set_state.item.policy_number);
    const unique = this.set_state.item.contributions.length === 1;
    if (!unique && !this.set_state.item.wants_back_to_one_contribution) {
      const total_amount = parseCurrencyToNumber(this.set_state.item.contributions[0].amount)
      + parseCurrencyToNumber(this.set_state.item.contributions[1].amount);
      this.appendItemToSummary(
        this.translate('monthly_contribution'),
        currencyFormat(total_amount),
      );
    }
    this.appendItemToSummary(
      unique || this.set_state.item.wants_back_to_one_contribution ? this.translate('new_billing_date') : this.translate('collection_date', { contribution_number: 'Primera' }),
      this.formatNewCollectionDay(
        this.set_state.item.last_collection_day, this.set_state.item.contributions[0]
          .collection_day,
      ),
    );
    this.appendItemToSummary(
      unique || this.set_state.item.wants_back_to_one_contribution ? this.translate('new_contribution_amount') : this.translate('contribution_amount'),
      this.formatNewAmount(
        parseCurrencyToNumber(this.set_state.item.last_contribution_amount),
        parseCurrencyToNumber(this.set_state.item.contributions[0].amount),
      ),
    );

    if (!this.set_state.item.wants_back_to_one_contribution
      && this.set_state.item.contributions.length > 1) {
      this.appendItemToSummary(
        this.translate('collection_date', { contribution_number: 'Segunda' }),
        this.formatCollectionDay(this.set_state.item.contributions[1].collection_day),
      );
      this.appendItemToSummary(
        this.translate('contribution_amount'),
        this.set_state.item.contributions[1].amount,
      );
    }
    this.appendItemToSummary(
      this.translate('periodicity'),
      unique || this.set_state.item.wants_back_to_one_contribution
        ? this.set_state.item.periodicity : this.translate('two_collections_per_month'),
    );
  }

  setState = (total_contribution: number) => {
    this.state.loaded = true;
    this.account_state.account.home_desire = true;
    this.account_state.account.periodicity = 'MENSUAL';
    this.account_state.account.amount = total_contribution;
    this.state.item.home_desired = true;
    this.state.item.total_contribution = total_contribution;
    this.state.item.contributions = [];
    this.set_state.item.contributions.forEach((contribution, index) => {
      const suspend = index === 1 && this.set_state.item.wants_back_to_one_contribution;
      if (!suspend) {
        this.state.item.contributions.push({
          id: contribution.id,
          amount: parseCurrencyToNumber(contribution.amount),
          collection_day: contribution.collection_day,
          distributions: contribution.distributions,
        });
      }
    });
  }

  confirmOperation = async () => {
    this.set_state.is_loading = true;
    let total_contribution = 0;
    const body: UpdateAllianzRecurringContributionsEntity = {
      contributions: [],
    };
    this.set_state.item.contributions.forEach((contribution, index) => {
      const suspend = index === 1 && this.set_state.item.wants_back_to_one_contribution;
      if (!suspend) {
        total_contribution += parseCurrencyToNumber(contribution.amount);
      }
      const distributions: Array<UpdateRecurringContributionDistributionEntity> = [];
      contribution.distributions.forEach((distribution) => {
        distributions.push({
          id: distribution.id,
          investor_goal_id: distribution.investor_goal_id,
          recurrent_assigned_amount: distribution.recurrent_assigned_amount.toFixed(2),
        });
      });
      body.contributions.push({
        id: contribution.id,
        amount: parseCurrencyToNumber(contribution.amount).toFixed(2),
        periodicity: 'monthly',
        collection_day: contribution.collection_day,
        distributions,
        suspend,
      });
    });
    try {
      await this.update_contributions_command.execute(body);
      await this.setState(total_contribution);
      this.nextStep();
    } catch {
      this.messageNotifier.showErrorNotification(
        this.translate('errors.on_update_recurring_deposit'),
      );
    } finally {
      this.set_state.is_loading = false;
    }
  }

  nextStep = () => {
    this.view.$emit('nextStep');
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }
}
